import {
  createBaseThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';

export const myTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        main: '#739072', // Green
      },
      secondary: {
        main: '#4F6F52', // Darker green
      },
      error: {
        main: '#FF2C2C', // Red
      },
      warning: {
        main: '#FF9800', // Orange
      },
      info: {
        main: '#D2B48C', // Dark yellow
      },
      success: {
        main: '#739072', // Green
      },
      background: {
        default: '#F0F0F0', // Light Grey
        paper: '#FFFFFF', // White
      },
      banner: {
        info: '#D2B48C', // Dark yellow
        error: '#FF2C2C', // Red
        text: '#FFFFFF', // White
        link: '#F0F0F0', // Light Grey
      },
      errorBackground: '#739072', // Darkest green
      warningBackground: '#739072', // Lightest green
      infoBackground: '#739072', // Green
      navigation: {
        background: '#3A4D39', // Darker green
        indicator: '#FFFFFF', // Lightest green
        color: '#FFFFFF', // Light green
        selectedColor: '#F0F0F0', // Light Grey
      },
    },
  }),
  defaultPageTheme: 'home',
  fontFamily: 'Adobe Clean, sans-serif',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#4F6F52', '#4F6F52'], shape: shapes.wave }), // Darker green and Darker green
    documentation: genPageTheme({ colors: ['#4F6F52', '#4F6F52'], shape: shapes.wave2 }), // Darker green and Darker green
    tool: genPageTheme({ colors: ['#4F6F52', '#4F6F52'], shape: shapes.round }), // Darker green and Darker green
    service: genPageTheme({ colors: ['#4F6F52', '#4F6F52'], shape: shapes.wave }), // Darker green and Darker green
    website: genPageTheme({ colors: ['#4F6F52', '#4F6F52'], shape: shapes.wave }), // Darker green and Darker green
    library: genPageTheme({ colors: ['#4F6F52', '#4F6F52'], shape: shapes.wave, }), // Darker green and Darker green
    other: genPageTheme({ colors: ['#4F6F52', '#4F6F52'], shape: shapes.wave }), // Darker green and Darker green
    app: genPageTheme({ colors: ['#4F6F52', '#4F6F52'], shape: shapes.wave }), // Darker green and Darker green
    apis: genPageTheme({ colors: ['#4F6F52', '#4F6F52'], shape: shapes.wave }), // Darker green and Darker green
  },
});
