import React from 'react';

import DefaultLogoFull from './logo_versioneer_icon.png';

const LogoIcon = () => {
  return (
    <img src={DefaultLogoFull} style={{ width: '40px'}}/>
  );
};

export default LogoIcon;